.footer {
  background-color: var(--off-black);
  color: var(--pure-white);
}

@media (min-width: 768px) {
  .footer__inner {
    width: calc(100% - (32px * 2));
    margin: auto;
  }
}

@media (min-width: 1024px) {
  .footer__inner {
    width: calc(100% - (80px * 2));
    max-width: 1272px;
  }
}

.footer__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.125rem;
  padding-top: 2.6rem;
  padding-bottom: 3.5rem;
  width: calc(100% - 48px);
  margin: 0px auto;
}

@media (min-width: 768px) {
  .footer__grid {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
    padding-bottom: 64px;
    padding-top: 64px;
    width: 100%;
    max-width: 1112px;
    align-items: baseline;
  }
}

@media (min-width: 1024px) {
  .footer__grid {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

.footer__logo-copyright__container {
  grid-column-start: 1;
  grid-column-end: 4;
}

.footer__logo {
  font-family: var(--heading-font);
  color: var(--pure-white);
  text-transform: capitalize;
  grid-column-start: 1;
  grid-column-end: 5;
}

@media (min-width: 460px) {
  .footer__logo {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .footer__logo {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.footer__nav {
  grid-column-start: 1;
  grid-column-end: 5;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .footer__nav {
    grid-column-start: 5;
    grid-column-end: 9;
  }
}

@media (min-width: 1024px) {
  .footer__nav {
    justify-self: end;
  }
}

.footer__links__column__title {
  color: var(--pure-white);
  text-transform: uppercase;
  display: block;
  font-family: var(--heading-font);
  font-feature-settings: var(--liga-setting);
  letter-spacing: 0.3px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .footer__links__column__title {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

.footer__link {
  color: var(--pure-white);
  font-size: 16px;
  line-height: 28px;
  font-family: var(--heading-font);
  font-feature-settings: var(--liga-setting);
  font-weight: 400;
  letter-spacing: 0.3px;
  transition: 0.1s opacity ease-out;
  overflow-wrap: anywhere;
}

.footer__link:hover,
.footer__link:active,
.footer__link:focus {
  opacity: 0.87;
}

.footer__form-section {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 3rem;
  max-width: 820px;
  display: none;
}

.footer__disclaimer {
  margin-top: 1.5rem;
  font-family: var(--body-font);
  font-feature-settings: var(--liga-setting);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--off-white);
  color: #d4d4d4;
}

.footer__disclaimer a {
  color: var(--pure-white);
}

.footer__copyright {
  font-family: var(--body-font);
  font-feature-settings: var(--liga-setting);
  font-weight: normal;
  letter-spacing: 0.2px;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: #5f6368;
  margin-top: 0.5rem;
  grid-column-start: 1;
  grid-column-end: 5;
}

@media (min-width: 768px) {
  .footer__copyright {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
